exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-csl-event-[slug]-js": () => import("./../../../src/pages/csl-event/[slug].js" /* webpackChunkName: "component---src-pages-csl-event-[slug]-js" */),
  "component---src-templates-csl-event-js": () => import("./../../../src/templates/cslEvent.js" /* webpackChunkName: "component---src-templates-csl-event-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-group-js": () => import("./../../../src/templates/group.js" /* webpackChunkName: "component---src-templates-group-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-list-events-js": () => import("./../../../src/templates/listEvents.js" /* webpackChunkName: "component---src-templates-list-events-js" */),
  "component---src-templates-list-groups-js": () => import("./../../../src/templates/listGroups.js" /* webpackChunkName: "component---src-templates-list-groups-js" */),
  "component---src-templates-list-tools-js": () => import("./../../../src/templates/listTools.js" /* webpackChunkName: "component---src-templates-list-tools-js" */),
  "component---src-templates-list-whatsapp-groups-js": () => import("./../../../src/templates/listWhatsappGroups.js" /* webpackChunkName: "component---src-templates-list-whatsapp-groups-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-page-whatsapp-com-js": () => import("./../../../src/templates/pageWhatsappCom.js" /* webpackChunkName: "component---src-templates-page-whatsapp-com-js" */),
  "component---src-templates-tool-js": () => import("./../../../src/templates/tool.js" /* webpackChunkName: "component---src-templates-tool-js" */),
  "slice---src-components-layout-footer-footer-js": () => import("./../../../src/components/Layout/Footer/Footer.js" /* webpackChunkName: "slice---src-components-layout-footer-footer-js" */),
  "slice---src-components-layout-header-js": () => import("./../../../src/components/Layout/Header.js" /* webpackChunkName: "slice---src-components-layout-header-js" */)
}

